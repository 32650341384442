import { createTheme, lighten } from '@mui/material'

const root = document.documentElement
const smallWidth = getComputedStyle(root).getPropertyValue('--width-small')
const mediumWidth = getComputedStyle(root).getPropertyValue('--width-medium')
const largeWidth = getComputedStyle(root).getPropertyValue('--width-large')
const fillWidth = getComputedStyle(root).getPropertyValue('--width-fill')
// const primaryColorLight = getComputedStyle(root).getPropertyValue('--col-primary-lite')
// const darkColor = getComputedStyle(root).getPropertyValue('--col-dark')
const greyColor = getComputedStyle(root).getPropertyValue('--col-grey-one')
const lightGreyColor = getComputedStyle(root).getPropertyValue('--col-light-grey')
const whiteColor = getComputedStyle(root).getPropertyValue('--col-white')
const colWhiteDark = getComputedStyle(root).getPropertyValue('--col-white-dark')
const errorColor = getComputedStyle(root).getPropertyValue('--col-error')

// flow slider and the data grid
const flowSliderAndDataGrid = getComputedStyle(root).getPropertyValue('--col-flow-slider')

// const errorLightColor = getComputedStyle(root).getPropertyValue('--col-danger-lite')
const warningColor = getComputedStyle(root).getPropertyValue('--col-warning')
const infoColor = getComputedStyle(root).getPropertyValue('--col-info')
const linkColor = getComputedStyle(root).getPropertyValue('--col-link')
const successColor = getComputedStyle(root).getPropertyValue('--col-success')
const textPrimaryColor = getComputedStyle(root).getPropertyValue('--col-text-primary')
const textSecondaryColor = getComputedStyle(root).getPropertyValue('--col-text-secondary')
const textDisabledColor = getComputedStyle(root).getPropertyValue('--col-text-disabled')
const textHintColor = getComputedStyle(root).getPropertyValue('--col-text-hint')
const workflowComponentWidth = getComputedStyle(root).getPropertyValue('--worflow-container-w')

// font-size
const fontOne = getComputedStyle(root).getPropertyValue('--font-base-1')

export const generateTheme = () => {
  const primaryColor = getComputedStyle(root).getPropertyValue('--col-primary')
  return createTheme({
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    },
    direction: 'ltr',
    mixins: {
      toolbar: {
        minHeight: 56,
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 48
        },
        '@media (min-width:600px)': {
          minHeight: 64
        }
      }
    },
    overrides: {},
    palette: {
      common: {
        black: '#000',
        white: '#fff'
      },
      mode: 'light',
      primary: {
        main: primaryColor
      },

      error: {
        main: errorColor
      },
      warning: {
        main: warningColor
      },
      info: {
        main: infoColor
      },
      link: {
        main: linkColor
      },
      success: {
        main: successColor
      },
      black: {
        main: '#000'
      },
      gray: {
        main: greyColor
      },
      lightgrey: {
        main: lightGreyColor
      },
      white: {
        main: whiteColor
      },
      red: {
        main: '#a8200d',
        contrastText: '#fff'
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161'
      },
      contrastThreshold: 3,

      tonalOffset: 0.2,
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
        disabled: textDisabledColor,
        hint: textHintColor
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      background: {
        paper: '#fff',
        default: '#fff'
      },
      action: {
        active: 'rgba(0, 0, 0)',
        hover: 'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,

        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: '#ccc',
        disabledOpacity: 0.38,
        focus: 'rgba(0,0,0,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12
      }
    },
    props: {},
    shape: {
      borderRadius: 0
    },
    shadows: [
      'none',
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
      '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
      '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
      '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
      '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
      '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
      '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
      '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
      '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
      '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
      '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
      '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
      '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
    ],

    components: {
      MuiAvatar: {
        defaultProps: {
          variant: 'customWithBorder',
          imgProps: {
            loading: 'lazy'
          }
        },
        variants: [
          {
            props: { variant: 'customWithBorder', noCustomStyle: false },
            style: (theme) => ({
              ...theme?.components?.MuiAvatar.variants?.find((v) => v.props.variant === 'square')?.style,
              border: '0.1px solid rgb(239, 239, 239)',
              boxShadow: 'rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px',
              borderRadius: '2px',
              background: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
              color: 'inherit'
            })
          },
          {
            props: { noCustomStyle: true },
            style: {}
          },
          {
            props: { size: 'extrasmall' },
            style: {
              width: '25px',
              height: '25px'
            }
          }
        ]
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: ({ theme }) => ({
              border: '1px solid rgb(40, 40, 40)',
              backgroundColor: theme.palette.red.main
            })
          },
          {
            props: { variant: 'light' },
            style: ({ theme, color }) => ({
              backgroundColor: color ? lighten(theme.palette[color].main, 0.95) : 'transparent',
              color: theme.palette[color].main,
              '&:hover': {
                backgroundColor: color ? lighten(theme.palette[color].main, 0.9) : 'transparent'
              }
            })
          },
          {
            props: { variant: 'transparent', darkbg: 'true' },
            style: {
              background: 'rgba(255, 255, 255, 0.2)' /* Semi-transparent white */,
              backdropFilter: 'blur(10px)' /* Blurry effect */,
              WebkitBackdropFilter: 'blur(10px)' /* For Safari browser compatibility */
            }
          },
          {
            props: { variant: 'log' },
            style: {
              backgroundColor: '#F2F2F2' /* Semi-transparent white */,
              border: '1px solid #161616',
              fontWeight: 400
            }
          },
          {
            props: { variant: 'folder' },
            style: {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              border: '1px solid var( --col-grey-two)',
              color: '#000000DE'
            }
          },
          {
            props: { variant: 'log', darkbg: 'true' },
            style: {
              background: 'rgba(255, 255, 255, 0.2)' /* Semi-transparent white */,
              backdropFilter: 'blur(10px)' /* Blurry effect */,
              WebkitBackdropFilter: 'blur(10px)' /* For Safari browser compatibility */,
              fontWeight: 400
            }
          }
        ],
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState?.size === 'small' && {
              height: '32px'
            }),
            textTransform: 'none',
            whiteSpace: 'nowrap',
            width: 'fit-content'
          })
        }
      },

      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'flex-start'
          }
        }
      },
      MuiTreeItem: {
        styleOverrides: {
          root: {
            // Apply styles to focused and expanded, but NOT selected
            '& .MuiTreeItem-content.Mui-focused:not(.Mui-selected), & .MuiTreeItem-content.Mui-expanded:not(.Mui-selected)': {
              backgroundColor: 'transparent'
            }
          }
        }
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true
        },
        styleOverrides: {
          tooltip: {
            maxHeight: '40vh'
          },
          popper: {
            overflowY: 'hidden',
            textOverflow: 'ellipsis'
          }
        }
      },
      MuiDataGrid: {
        variants: [
          {
            props: { orgGrid: 'true' },
            style: {
              border: 0,
              '& .MuiDataGrid-row': {
                margin: '10px 0',
                backgroundColor: 'white',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#daffda !important'
                }
              },
              '& .MuiDataGrid-cell': {
                '&:focus': {
                  outline: 0
                }
              },
              '& .MuiDataGrid-columnHeaders': {
                border: 0,
                paddingTop: 0,
                paddingBottom: 0,
                '&:focus': {
                  outline: 0
                }
              },
              '& .MuiDataGrid-columnHeader': {
                '&:focus, &:focus-within': {
                  outline: 0
                }
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'flex',
                gap: '18px'
              }
            }
          }
        ],
        styleOverrides: {
          root: {
            '--DataGrid-containerBackground': flowSliderAndDataGrid,
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            },
            '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none'
            },
            '& .MuiDataGrid-cell:focus-within .MuiDataGrid-cellCheckbox, & .MuiDataGrid-columnHeader:focus-within .MuiDataGrid-columnHeaderCheckbox':
              {
                outline: 'none'
              },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover
              }
            },
            '.MuiDataGrid-virtualScroller': {
              overflowX: 'hidden' // Hides horizontal scrolling inside the virtual scroller
            },
            '.MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none' // Hides the scrollbar
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none'
            },
            '.MuiDataGrid-selectedRowCount': {
              visibility: 'hidden'
            },
            border: '1px solid #161616'
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0px 16px 16px',
            '& .MuiAutocomplete-popper': {
              paddingLeft: '16px',
              paddingRight: '16px',
              width: '100% !important'
            }
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: 0,
            width: '100%'
          },
          root: ({ ownerState }) => ({
            ...(ownerState.halfopen === 'true' && {
              '& .Mui-expanded': {
                margin: 0
              },
              height: '64px'
            }),

            ...(ownerState.variant !== 'pluginstep' && {
              backgroundColor: '#ededed',
              transition: 'background-color 0.3s, box-shadow 0.3s, border 0.3s',
              border: ' 0.2px solid rgb(147, 146, 146)',
              boxSizing: 'border-box',
              '&:hover': {
                backgroundColor: '#fff'
              }
            }),
            // ...(!['pluginstep','suggestion'].includes(ownerState.variant) && {
            ...(ownerState.variant === 'logSteps' && {
              padding: '0px',
              backgroundColor: '#e8e8e8',
              border: '0px',
              transition: 'background-color 0.3s, box-shadow 0.3s, border 0.3s',
              borderTop: '0.2px solid #ccc',
              marginBottom: '5px',
              borderBottom: '0.2px solid #ccc',
              boxSizing: 'content-box',
              flexDirection: 'row-reverse',
              '&:hover': {
                backgroundColor: '#e8e8e8'
              }
            }),

            ...(ownerState.variant === 'pluginstep' && {
              backgroundColor: 'transparent',
              boxSizing: 'border-box'
            }),
            ...(ownerState.variant === 'suggestion' && {
              height: '40px',
              minHeight: 'unset !important',
              boxSizing: 'border-box'
            }),
            ...(ownerState.styledSummery && {
              border: 'none',
              margin: '0 !important',
              background: 'transparent',
              padding: '0',
              '&:hover': { background: 'transparent' }
            })
          })
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            backgroundColor: 'transparent',
            ...(ownerState.halfopen === 'true' && {
              '& .Mui-focusVisible': {
                backgroundColor: 'transparent !important'
              },
              '& .Mui-expanded': {
                minHeight: 'unset !important'
              }
            }),
            ...(ownerState.variant === 'pluginstep' &&
              !ownerState.noBorder && {
                borderTop: '1px solid rgba(0, 0, 0, 0.12)'
              }),
            ...(ownerState.variant === 'topBottomBorder' && {
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }),
            '&.MuiAccordion-root.Mui-expanded': {
              margin: 0
            },
            ...(ownerState.variant === 'flowpage' && {
              '& .MuiAccordionSummary-root': {
                backgroundColor: 'transparent !important',
                border: 'none',
                '& .Mui-expanded': {
                  margin: 0
                },
                height: '64px'
              },
              background: 'rgba(250, 250, 250, 0.8) !important' /* Semi-transparent white with less opacity */,
              backdropFilter: 'blur(10px)' /* Blurry effect */,
              border: '0px solid rgb(0,0,0,0.12)',
              borderBottom: '1px solid rgb(0,0,0,.5)',
              // boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.1)',
              WebkitBackdropFilter: 'blur(10px)' /* For Safari browser compatibility */
            })
          })
        }
      },
      MuiDivider: {
        variants: [
          {
            props: { variant: 'normal' },
            style: {
              borderColor: '#e8e8e8'
            }
          },
          {
            props: { variant: 'sharp' },
            style: {
              borderColor: 'rgb(0, 0, 0, 0.5)' // Adjusted opacity for less bold appearance
              // borderWidth: '0.5px' // Hairline width
            }
          }
        ]
      },
      MuiTabs: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.darkbg === 'true' && {
              '& .MuiTabs-indicator': {
                backgroundColor: whiteColor,
                color: whiteColor,
                top: '40px'
              },
              '& .MuiTab-root': {
                color: whiteColor,
                fontWeight: 400,
                fontSize: fontOne
              },
              '& .MuiTab-root.Mui-selected': {
                color: whiteColor,
                fontWeight: 700,
                fontSize: fontOne
              }
            }),
            ...(ownerState.darkbg === 'false' && {
              '& .MuiTabs-indicator': {
                backgroundColor: '#000',
                color: '#000',
                top: '40px'
              },
              '& .MuiTab-root': {
                color: '#000',
                fontSize: '12px',
                padding: '12px 0px',
                textTransform: 'unset !important'
              },
              '& .MuiTab-root.Mui-selected': {
                color: '#000',
                fontSize: '12px',
                padding: '12px 0px'
              }
            })
          })
        }
      },

      MuiFab: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {}
            }
          }
        }
      },

      MuiIconButton: {
        variants: [
          {
            props: { variant: 'iconbuttonModal' },
            style: {
              position: 'absolute',
              right: 24,
              top: 28,
              color: (theme) => theme.palette.grey[500]
            }
          },
          {
            props: { variant: 'mediumSize' },
            style: {
              width: '30px',
              height: '30px'
            }
          }
        ],
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.darkbg === 'true' && {
              color: colWhiteDark
            }),
            padding: 0,
            borderRadius: 0
          })
        }
      },
      MuiSvgIcon: {
        variants: [
          {
            props: { variant: 'customSize' },
            style: {
              fontSize: '20px'
            }
          }
        ],
        styleOverrides: {
          root: ({ ownerState }) => ({
            opacity: 0.8,
            ...(ownerState?.expandAcoordion && {
              transition: 'all 0.3s', // Transition effect
              padding: '4px', // Padding similar to `p-1`
              fontSize: ownerState?.big ? '40px' : ownerState?.medium ? '30px' : '20px',
              color: 'rgba(0, 0, 0, 0.2)', // Matches `text-black/20`
              transform: ownerState?.isExpanded ? `rotate(${ownerState?.selectedWorkspace ? '180' : '90'}deg)` : 'rotate(0deg)' // Rotation logic
            }),
            ...(ownerState?.variant === 'customSize' &&
              ownerState?.folderBtn && {
                color: 'rgba(0, 0, 0, 0.4)', // Matches `text-black/40`
                transition: 'color 0.3s' // Smooth hover transition
              }),
            ...(ownerState?.darkColor && {
              color: 'black'
            })
          })
        }
      },
      MuiCard: {
        defaultProps: {
          variant: 'outlined'
        },
        styleOverrides: {
          root: {
            boxSizing: 'border-box'
          }
        },
        variants: [
          {
            props: { variant: 'carousel' },
            style: () => ({
              display: 'flex',
              overflow: 'auto',
              scrollSnapType: 'x mandatory',
              '& > *': {
                scrollSnapAlign: 'center'
              },
              '::-webkit-scrollbar': { display: 'none' }
            })
          }
        ]
      },
      MuiChip: {
        variants: [
          {
            props: { size: 'small' },
            style: ({ ownerState }) => ({
              '& .MuiChip-labelSmall ': {
                fontSize: '12px'
              },
              borderRadius: ownerState.borderRadius || 0
            })
          },
          {
            props: { size: 'extrasmall' },
            style: ({ ownerState }) => ({
              '& .MuiChip-label ': {
                fontSize: '10px',
                fontFamily: 'monospace',
                padding: '1px 2px !important'
              },
              height: '14px !important',
              borderRadius: ownerState.borderRadius || 0
            })
          },
          {
            props: { color: 'white' },
            style: ({ ownerState }) => ({
              backgroundColor: '#f5f5f5',
              color: 'black', // Assuming black text on white background
              '& .MuiChip-label': {
                fontSize: 'x-small'
              },
              borderRadius: ownerState.borderRadius || 0
            })
          }
        ],
        defaultProps: {
          size: 'small'
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: ownerState.borderRadius || 0
          })
        }
      },

      MuiTextField: {
        defaultProps: {
          spellCheck: false,
          autoComplete: 'off'
        },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.width === 'small' && {
              width: smallWidth
            }),
            ...(ownerState.width === 'medium' && {
              width: mediumWidth
            }),
            ...(ownerState.width === 'large' && {
              width: largeWidth
            }),
            ...(ownerState.width === 'fill' && {
              width: fillWidth
            }),
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: textHintColor
            },
            ...(ownerState.noborder === 'true' && {
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            }),
            ...(ownerState.createfunction === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                padding: '0px'
              },
              borderLeft: '2px solid #ccc !important'
            }),
            ...(ownerState.dhcustompadding === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                padding: '8px'
              }
            }),
            ...(ownerState.paddingtwelvepx === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                padding: '12px'
              }
            }),
            ...(ownerState.projectSlider === 'true' && {
              color: '#747474',
              margin: '4px',
              marginBottom: '8px',
              fontFamily: '"Inter", sans-serif',
              '& .MuiOutlinedInput-root': {
                padding: '0px',
                margin: '0px'
              },
              '& .MuiInputBase-input': {
                fontWeight: '600',
                fontSize: '16px'
              },
              '& .MuiInputBase-input::placeholder': {
                fontWeight: '600',
                fontSize: '16px'
              }
            }),
            ...(ownerState.workflowtitle === 'true' && {
              width: ownerState?.workflow
                ? ''
                : ownerState?.autocompleteRecommendations
                ? '600px'
                : !ownerState?.recommendations
                ? workflowComponentWidth
                : smallWidth,
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                padding: 0,
                fontWeight: '400',
                fontSize: ownerState?.autocompleteRecommendations
                  ? theme.typography.h3.fontSize
                  : ownerState?.recommendations
                  ? '40px'
                  : '24px',
                fontFamily: "'Times New Roman', serif",
                lineHeight: ownerState?.recommendations ? '43.5px' : 'normal'
              },
              ...(ownerState?.autocompleteRecommendations && {
                '& .MuiInputLabel-root, & .MuiInputBase-input': {
                  fontSize: theme.typography.h3.fontSize
                },
                '&.Mui-focused .MuiInputLabel-root, & .MuiInputBase-input:not(:placeholder-shown) + .MuiInputLabel-root': {
                  opacity: 0
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                }
              })
            }),
            ...(ownerState.workflowdescription === 'true' && {
              '& .MuiOutlinedInput-root ': {
                padding: 0
              }
            }),

            ...(ownerState.commentstep === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                padding: 0
              },
              '& .MuiInputBase-root': {
                padding: 0
              }
            }),
            ...(ownerState.scriptname === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                fontFamily: '"Inter", sans-serif',
                padding: '5px  8px'
              }
            }),
            ...(ownerState.projectname === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                padding: 0,
                ...theme.typography.body2
              }
            }),
            ...(ownerState.steptitle === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                fontWeight: '500',
                padding: 0,
                fontSize: '18px',
                lineHeight: '29.05px'
              }
            }),
            ...(ownerState.stepdescription === 'true' && {
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                fontWeight: '600',
                padding: 0,
                fontSize: '18px',
                lineHeight: '29.05px'
              }
            }),
            ...(ownerState.darkbg === 'true' && {
              color: whiteColor
            }),
            ...(ownerState.DataGridAndButtonWithSearchBarCombined === 'true' && {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                border: 'none'
              },
              '& .MuiOutlinedInput-root': {
                border: '1px solid #161616 !important',
                borderBottom: 'none !important'
              }
            })
          })
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          select: {
            width: 'unset'
          }
        }
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave'
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.width === 'small' && {
              width: smallWidth
            }),
            ...(ownerState.width === 'medium' && {
              width: mediumWidth
            }),
            backgroundColor: 'white',
            ...(ownerState.actionversion === 'true' && {
              '& .MuiOutlinedInput-input': {
                padding: '0 15px'
              }
            }),
            ...(ownerState.custom8px === 'true' && {
              '& .MuiOutlinedInput-input': {
                padding: '8px'
              }
            }),
            ...(ownerState.triggerheader === 'true' && {
              backgroundColor: 'transparent',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 0
              },

              '& .MuiOutlinedInput-input': {
                padding: 0
              },
              fontFamily: '"Inter", sans-serif',

              fontWeight: '600',
              padding: 0,
              lineHeight: '29.05px',
              fontSize: '24px'
            })
          })
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: 'black'
          }
        },
        variants: [
          {
            props: { color: 'error' },
            style: {
              color: errorColor
            }
          },
          {
            props: { color: 'success' },
            style: {
              color: successColor
            }
          }
        ]
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small'
        }
      },

      MuiInputBase: {
        defaultProps: {
          autoComplete: 'off'
        },
        styleOverrides: {
          root: {
            color: 'inherit',
            width: '100%',
            '& .MuiInputBase-input': {
              transition: 'width 200ms ease',
              display: 'flex',
              alignItems: 'center',
              outline: 'none'
            }
          }
        }
      },
      MuiFormGroup: {
        variants: [
          {
            props: { variant: 'notification' },
            style: {
              padding: '10px 20px 10px 0 !important'
            }
          },
          {
            props: { variant: 'notification', status: 'active' },
            style: {
              backgroundColor: 'whitesmoke !important',
              color: 'green !important'
            }
          },
          {
            props: { variant: 'notification', status: 'paused' },
            style: {
              backgroundColor: 'var(--navbar-bg-pause) !important',
              border: '1px solid #ccc !important'
            }
          },
          {
            props: { variant: 'notification', status: 'deleted' },
            style: {
              backgroundColor: 'var(--navbar-bg-delete) !important',
              border: '1px solid #ccc !important'
            }
          }
        ]
      },
      MuiListItemText: {
        defaultProps: {
          primaryTypographyProps: { variant: 'body2' }
        }
      }
    },
    typography: {
      h1: {
        fontFamily: '"Times New Roman", sans-serif'
      },
      h2: {
        fontFamily: '"Times New Roman", sans-serif'
      },
      h3: {
        fontFamily: '"Times New Roman", sans-serif'
      },
      h4: {
        fontFamily: '"Times New Roman", sans-serif'
      },
      h5: {
        fontFamily: '"Times New Roman", sans-serif'
      },
      h6: {
        fontFamily: '"Times New Roman", sans-serif'
      }
    },
    transitions: {
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
      },
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195
      }
    }
  })
}
