import { useLocation, useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import React from 'react'
import { Box, Divider, List, ListItemButton, ListItemText } from '@mui/material'
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import SecurityIcon from '@mui/icons-material/Security'
import DeleteIcon from '@mui/icons-material/Delete'
import PauseIcon from '@mui/icons-material/Pause'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import ProjectList from '../../components/projectdashboard/projectList/projectList.tsx'
import CreateNewPopover from '../../components/projectdashboard/createNewPopover.tsx'
import LeaveWorkspaceButton from './LeaveWorkspaceButton.tsx'
import config from '../../config'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import selectActiveUser from '../../store/user/userSelector'
import './projectPage.scss'
import { isThreeMonthOld } from '../interface/utils/InterfaceUtils.ts'

function ProjectSliderOptionsAndButtons({ orgId, open, projectId }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentOrgCreatedBy, userData, orgCreatedAt } = useCustomSelector((state: $ReduxCoreType) => ({
    currentOrgCreatedBy: state.orgs?.orgs?.[orgId]?.created_by,
    userData: selectActiveUser(state),
    orgCreatedAt: state.orgs?.orgs?.[orgId]?.created_at
  }))
  const handleMetrics = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/metrics`)
  }
  const handleDashBoard = () => {
    navigate(`${config.projectsBaseUrl}/${orgId}/dashboard`)
  }
  const handleHome = () => {
    navigate(`${config.projectsBaseUrl}/${orgId}`)
  }
  const handlePaused = () => {
    navigate(`${config.projectsBaseUrl}/${orgId}/pausedflows`)
  }
  const handleDeleted = () => {
    navigate(`${config.projectsBaseUrl}/${orgId}/deletedflows`)
  }

  const isThreeMonthOldOrg = isThreeMonthOld(orgCreatedAt)
  return (
    <Box className='flex flex-col w-full h-full overflow-y-scroll  '>
      <Box className={`pb-64 lists-when-dropdown-close ${!open && 'visible'}`}>
        <Box className='space-y-2'>
          <Box className='p-2'>
            <CreateNewPopover defaultProjectId={`proj${orgId}`} />
          </Box>
          <List>
            <ListItemButton
              onClick={handleHome}
              selected={location.pathname === `${config.projectsBaseUrl}/${orgId}`}
              className='flex item-center gap-2 py-1'
            >
              <HomeIcon />
              <ListItemText primary='Home' />
            </ListItemButton>
            <ListItemButton
              onClick={handleDashBoard}
              selected={location.pathname === `${config.projectsBaseUrl}/${orgId}/dashboard`}
              className='flex item-center gap-2 py-1'
            >
              <SpaceDashboardIcon />
              <ListItemText primary='Dashboard' />
            </ListItemButton>
            <ListItemButton
              onClick={handleMetrics}
              selected={location.pathname.includes('metrics')}
              className='flex item-center gap-2 py-1'
            >
              <EqualizerRoundedIcon />
              <ListItemText primary='Metrics' />
            </ListItemButton>

            <ListItemButton
              onClick={() => navigate(`${config.authBaseUrl}/${orgId}`)}
              selected={
                location.pathname.startsWith(config.authBaseUrl) ||
                (location.pathname.startsWith(`/projects/${orgId}/appsexplore`) && !projectId)
              }
              className='flex item-center gap-2 py-1'
            >
              <SecurityIcon />
              <ListItemText primary='Connections' />
            </ListItemButton>
          </List>
          <Divider className='my-2' variant='normal' />

          <ProjectList isThreeMonthOldOrg={isThreeMonthOldOrg} />

          <Box>
            <List>
              <ListItemButton
                onClick={handlePaused}
                selected={location.pathname === `${config.projectsBaseUrl}/${orgId}/pausedflows`}
                className='flex item-center gap-2 py-1'
              >
                <PauseIcon />
                <ListItemText primary='Paused Flows/Folders' />
              </ListItemButton>
              <ListItemButton
                onClick={handleDeleted}
                selected={location.pathname === `${config.projectsBaseUrl}/${orgId}/deletedflows`}
                className='flex item-center gap-2 py-1'
              >
                <DeleteIcon />
                <ListItemText primary='Trash' />
              </ListItemButton>
            </List>
          </Box>
        </Box>
        {/* list of all other menus */}
      </Box>
      {open && userData?.userId !== currentOrgCreatedBy && <LeaveWorkspaceButton />}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(ProjectSliderOptionsAndButtons), [ParamsEnums.orgId, ParamsEnums.projectId]))
