import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Close } from '@mui/icons-material'
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, IconButton, MenuItem } from '@mui/material'
import { openModalRedux, updateAppInfoState } from '../../../../store/appInfo/appInfoSlice.ts'
import { convertScriptToTemplate } from '../../../../api'
import { ParamsEnums } from '../../../../enums'
import addUrlDataHoc from '../../../../hoc/addUrlDataHoc.tsx'
import { infoToast } from '../../../customToast'
import { useCustomSelector } from '../../../../utils/deepCheckSelector'
import { setScriptsReducer } from '../../../../store/scripts/scriptsSlice'

function FlowShareButton({ scriptId, projectId }) {
  const { templateId } = useCustomSelector((state) => ({
    templateId: state.scripts.scripts?.[scriptId]?.template_id
  }))
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [templateCreated, setTemplateCreated] = useState(false)
  const textRef = useRef(null)

  function handleClose() {
    setOpen(false)
    setTemplateCreated(false)
  }
  const handleCopy = () => {
    if (textRef.current) {
      navigator.clipboard.writeText(textRef.current.innerText)
      infoToast('URL copied!')
      handleClose()
    }
  }
  const handleDuplicateBtn = () => {
    dispatch(openModalRedux({ modalType: 'duplicateScript' }))
    dispatch(updateAppInfoState({ duplicateFlowId: scriptId }))
    handleClose()
  }
  const handleMoveBtn = () => {
    dispatch(openModalRedux({ modalType: 'MoveScript' }))
    dispatch(updateAppInfoState({ duplicateFlowId: scriptId }))
    handleClose()
  }
  const createTemplate = async (e) => {
    e.stopPropagation()
    const newTemplate = await convertScriptToTemplate({ id: scriptId, project_id: projectId })
    if (newTemplate?.identifier) {
      dispatch(setScriptsReducer([newTemplate]))
      setTemplateCreated(true)
    }
  }
  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
      >
        Share
      </MenuItem>
      <Dialog fullWidth open={open} onClose={handleClose}>
        {!templateCreated ? (
          <>
            <DialogTitle className='flex justify-between items-center'>
              Share Flow
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className='py-5 flex flex-col w-full gap-5'>
              {templateId === scriptId ? (
                <Box className='flex flex-col gap-1 w-full py-2'>
                  Your flow is publicly available as a template! Share it using the provided link.
                  <Box className='flex items-center gap-1 w-full py-2'>
                    <Card ref={textRef} className='w-full p-2'>
                      {process.env.REACT_APP_FRONTEND_URL}/template/{scriptId}
                    </Card>
                    <Button variant='contained' onClick={handleCopy}>
                      Copy
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box className='flex flex-col gap-1 w-full'>
                  Share your flow with the world by turning it into a template!.
                  <Button variant='contained' onClick={createTemplate}>
                    Create Template
                  </Button>
                </Box>
              )}
              <Box className='flex flex-col gap-1 w-full'>
                Move or Duplicate your flow into any folder or Organization.
                <Box className='flex gap-2'>
                  <Button variant='contained' onClick={handleMoveBtn}>
                    Move
                  </Button>
                  <Button variant='contained' onClick={handleDuplicateBtn}>
                    Duplicate
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle className='flex justify-between items-center'>
              Template Created Successfully
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className='py-5 flex flex-col w-full gap-1'>
              Now your Flow is publically available as a template, you can also share it with the provided URL.
              <Box className='flex items-center gap-1 w-full py-2'>
                <Card ref={textRef} className='w-full p-2'>
                  {process.env.REACT_APP_FRONTEND_URL}/template/{scriptId}
                </Card>
                <Button variant='contained' onClick={handleCopy}>
                  Copy
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  )
}
export default React.memo(addUrlDataHoc(React.memo(FlowShareButton), [ParamsEnums.scriptId, ParamsEnums.projectId]))
