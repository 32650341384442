import { Add, Api, ArrowBackIos, FiberManualRecord, Webhook } from '@mui/icons-material'
import { useNavigate, useOutletContext } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Card, Skeleton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import './PreviewSection.scss'
import { MiscTypes, ParamsEnums, THUNK_CALL_STATUS, Tabnames } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import isEqual, { useCustomSelector } from '../../../utils/deepCheckSelector'
import { sendDataToParentInEmbed } from '../../../utils/utilities'
import { saveScriptTemplate, saveScripts } from '../../../store/scripts/scriptsThunk'
import {
  useFetchActionsTriggers,
  useFetchPlugins,
  useInitialPluginsWithActions
} from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { errorToast } from '../../customToast'
import Templates from '../../sliderLayout/Templates.tsx'
import Breadcrumb from '../../breadcrumb/Breadcrumb.tsx'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import CustomCombinationsComponent from './CustomCombinationsComponent.tsx'
import DefaultCombinations from './DefaultCombinations.tsx'
import PluginSearchBar from '../../projectdashboard/PluginSearchBar.tsx'
import config from '../../../config'
import { calculateEnabledServiceObject } from '../../../store/scripts/scriptsSelector'
import DeletedAndPausedFlows from './DeletedAndPausedFlows.tsx'
import SelectedPlugToAddStep from '../../masterSliderComponent/SelectedPlugToAddStep.tsx'
import { JSIcon } from '../../../assests/assestsIndex.ts'
import SelectedServiceForIntegration from './SelectedServiceForIntegration.tsx'
import EnabledFlows from './EnabledFlows.tsx'
import CompleteAndInCompleteAutomationsList from '../../../pages/dashboard/CompleteAndInCompleteAutomationsList.tsx'

type PreviewSectionProps = {
  orgId: string
  projectId: string
  serviceId: string
  integrationServiceId?: string
  serviceTypeFromProps?: 'both' | 'trigger' | 'action'
  showEnabled?: boolean
  showTemplates?: boolean
  featuredCombinationType?: boolean
  showSubHeading?: boolean
  heading?: string
  authenticationComponent?: () => JSX.Element
  isEmbedUrl?: boolean
}
/**
 * PreviewSection component for displaying integration preview and options.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.orgId - The organization ID
 * @param {string} props.projectId - The project ID
 * @param {string} props.serviceId - The service ID
 * @param {string} [props.integrationServiceId=''] - The integration service ID
 * @param {'both' | 'trigger' | 'action'} [props.serviceTypeFromProps='both'] - The service type
 * @param {boolean} [props.showEnabled=false] - Whether to show enabled services
 * @param {boolean} [props.showTemplates=false] - Whether to show templates
 * @param {boolean} [props.featuredCombinationType=false] - Whether to use featured combination type
 * @param {boolean} [props.showSubHeading=true] - Whether to show sub-heading
 * @param {string} [props.heading=''] - The heading text
 * @param {Function} [props.authenticationComponent=() => null] - The authentication component
 * @param {boolean} [props.isEmbedUrl] - Whether the component is embedded in a URL
 * @returns {JSX.Element} The PreviewSection component
 */

function PreviewSection({
  orgId,
  projectId,
  serviceId,
  integrationServiceId = '',
  serviceTypeFromProps = 'both',
  showEnabled = false,
  templatesHeading,
  buttonHeading = '',
  showTemplates = false,
  featuredCombinationType = false,
  showSubHeading = true,
  showDeletedFlows = false,
  heading = '',
  authenticationComponent = () => null,
  isEmbedUrl,
  pagesubheading = '',
  permittedEvents = [],
  unpermittedEvents = [],
  hideApi = false,
  hideFunction = false
}: PreviewSectionProps) {
  const context = useOutletContext()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentIntegrationSettings, enabledServiceObject, pluginData, mode, lengthOfFlowsPresent } = useCustomSelector((state) => {
    const currentIntegrationSettings = state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {}
    return {
      enabledServiceObject: showEnabled
        ? calculateEnabledServiceObject(state, projectId, integrationServiceId, currentIntegrationSettings?.permitsingleflow)
        : {},
      currentIntegrationSettings,
      pluginData: state.allPlugins.pluginData,
      mode: state.appInfo.mode,
      lengthOfFlowsPresent: state.scripts?.orderOfScriptsLastUpdated?.length
    }
  })
  const { data: initialPlugins } = useInitialPluginsWithActions(orgId)
  const connectedPluginIds = initialPlugins?.map((plugin) => plugin?.pluginrowid)
  const [servicesData, setServicesData] = useState({ pluginsArray: [], pluginsObj: {}, isLoading: false })
  const { pluginsArray, pluginsObj, isLoading } = servicesData
  const [showFlowsList, setShowFlowsList] = useState(showEnabled && lengthOfFlowsPresent)
  const [selectedService, setSelectedService] = useState(false)
  const { data: bothServicesJson, isLoading: integrationServiceLoading } = useFetchPlugins(orgId, [], [integrationServiceId, serviceId])

  const integrationService = bothServicesJson?.[integrationServiceId]
  const serviceActionsAndTriggers = useFetchActionsTriggers(integrationServiceId, 'both', permittedEvents)?.data || {}
  const serviceActionsArray = Object.values(serviceActionsAndTriggers)?.filter(
    (action) => !unpermittedEvents.includes(action.rowid) && action.type !== 'trigger'
  )
  const serviceTriggersArray = Object.values(serviceActionsAndTriggers)?.filter(
    (action) => !unpermittedEvents.includes(action.rowid) && action.type === 'trigger'
  )

  useEffect(() => {
    if (!(selectedService === serviceId || selectedService?.rowid === serviceId)) {
      if (!serviceId) setSelectedService(false)
      else if (serviceId === 'function' || serviceId === 'api' || serviceId === 'webhook') setSelectedService(serviceId)
      else {
        const serviceObject = bothServicesJson?.[serviceId] || pluginsObj?.[serviceId] || false
        setSelectedService(serviceObject)
      }
    }
  }, [serviceId, servicesData, integrationServiceLoading])

  useEffect(() => {
    setShowFlowsList(showEnabled && lengthOfFlowsPresent)
  }, [lengthOfFlowsPresent, showEnabled])

  const createFlowWithTemplate = async (template) => {
    const templateId = template?.template_id || template?.published_json_script?.identifier
    dispatch(saveScriptTemplate({ projectId, templateId, meta: context?.meta })).then((e) => {
      if (e.payload?.id) {
        sendDataToParentInEmbed('initiated', e.payload)
        createScriptAndNavigate(e.payload.id)
      } else errorToast('Flow creation failed.')
    })
  }
  const createScriptAndNavigate = async (scriptId, queryparams) => {
    let flowUrl = isEmbedUrl
      ? `/integrations/embed/${orgId}/${projectId}/${serviceId ? `service/${serviceId}/` : ''}workflow/${scriptId}/${Tabnames.DRAFT}`
      : `${config.projectsBaseUrl}/${orgId}/${projectId || `proj${orgId}`}/service/${serviceId || integrationServiceId}${
          config.workflowBaseUrl
        }/${scriptId}/${Tabnames.DRAFT}`
    if (queryparams) flowUrl += `?${queryparams}`
    navigate(flowUrl)
  }
  const handleIntegrationTriggerClick = (type, trigger) => {
    navigate(
      isEmbedUrl
        ? `/integrations/embed/${orgId}/${projectId}/service/${type === 'trigger' ? trigger?.rowid : type}`
        : `/projects/${orgId}${projectId ? `/${projectId}` : ''}/appsexplore/servicepage/${integrationServiceId}/service/${
            type === 'trigger' ? trigger?.rowid : type
          }`
    )
  }

  const createflow = async (triggerEvent, pluginEvent) => {
    const currentProjectId = projectId || `proj${orgId}`
    const triggerEventRowId = triggerEvent?.rowid
    const pluginEventRowId = pluginEvent?.rowid
    let triggerDetails
    if (triggerEventRowId) {
      if (triggerEventRowId === 'webhook') {
        triggerDetails = {
          triggerType: 'webhook',
          type: 'add',
          response: {
            data: currentIntegrationSettings?.response?.value || '',
            type: 'response',
            responseType: currentIntegrationSettings?.response?.type
          }
        }
        if (currentIntegrationSettings?.config?.runFlowOneByOne) triggerDetails['loops'] = { loopsEnabled: true }
        if (currentIntegrationSettings?.config?.runFlowWithArrayKey)
          triggerDetails['preProcessFilter'] = {
            type: 'filterByKey',
            value: {
              html: currentIntegrationSettings?.config?.runFlowWithArrayKey,
              value: currentIntegrationSettings?.config?.runFlowWithArrayKey
            }
          }
      } else
        triggerDetails = {
          eventId: triggerEventRowId
        }
    }
    let functionDetails
    if (['api', 'function']?.includes(pluginEventRowId)) {
      functionDetails = {
        type: pluginEventRowId
      }
    } else if (pluginEventRowId) {
      functionDetails = {
        type: 'plugin',
        eventIdArray: [pluginEventRowId]
      }
    }

    dispatch(
      saveScripts({
        project_id: currentProjectId,
        org_id: orgId,
        script: '//write your code here.',
        triggerDetails,
        functionDetails,
        pluginData,
        description: triggerEvent?.description || pluginEvent?.description,
        title: triggerEvent?.name || pluginEvent?.name,
        required: isEmbedUrl ? integrationServiceId : undefined,
        isBasicFlow: isEmbedUrl && integrationServiceId === 'webhook',
        meta: context?.meta
      })
    ).then((e) => {
      const scriptId = e.payload?.id
      if (scriptId && e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
        let queryparams = ''
        sendDataToParentInEmbed('initiated', e.payload)
        if (!triggerDetails) queryparams += 'stepId=SET_TRIGGER&slugName=selecttrigger'
        else if (triggerDetails?.eventId) queryparams += 'stepId=SET_TRIGGER&slugName=trigger'
        else {
          const stepSlugName = e.payload?.json_script?.order?.root?.[0]
          if (!stepSlugName) queryparams = `stepId=ADD_STEP&position=undefined&parent=root`
          const stepIdentifier = e.payload?.json_script?.blocks?.[stepSlugName]?.identifier
          if (stepSlugName && stepIdentifier) queryparams = `stepId=${stepIdentifier}&slugName=${stepSlugName}`
        }
        createScriptAndNavigate(scriptId, queryparams)
      } else errorToast('Flow creation failed.')
    })
  }
  const serviceType = useMemo(() => {
    if (integrationServiceId !== 'webhook' && !serviceActionsArray?.length && !serviceTriggersArray?.length) return null
    if (serviceTypeFromProps !== 'both') return serviceTypeFromProps

    if (serviceActionsArray?.length === 0) return 'trigger'
    if (serviceTriggersArray?.length === 0) return 'action'
    return 'both'
  }, [serviceTypeFromProps, serviceActionsArray?.length, serviceTriggersArray?.length])
  const renderServiceButton = (service, onClickHandler, startIcon) => {
    if (service.name === 'API') {
      service = {
        name: 'Custom API',
        description: 'Execute a custom API request.'
      }
    }
    if (service.name === 'Function') {
      service = {
        name: 'Custom Function',
        description: 'Run a custom JavaScript function.'
      }
    }
    return (
      <Box className='p-2 grid-item'>
        <Card variant='outlined' onClick={onClickHandler} className='service-block gap-3 column '>
          <Box className='flex-spaceBetween-center  gap-2  '>
            <Box className='flex-start-center  gap-2 '>
              <Box className='h-100'>
                <IconWrapper component={startIcon} size='32px' />
              </Box>
              <Typography className='service-title '>{service.name}</Typography>
            </Box>
            {Array.isArray(connectedPluginIds) && (connectedPluginIds || [])?.includes(service?.rowid) ? (
              <FiberManualRecord fontSize='smaller' color='success' />
            ) : null}
          </Box>

          {service?.description && <Typography className='service-description w-100'>{service?.description}</Typography>}
        </Card>
      </Box>
    )
  }
  const renderAvailableServices = (services) =>
    services.map((service) =>
      renderServiceButton(
        service,
        () => handleIntegrationTriggerClick('trigger', service),
        <img src={service?.iconurl || ''} alt='' width='100%' height='100%' />
      )
    )
  const restrictedDomain = integrationService?.domain
  const areMoreFlowsPossible =
    !Object.keys(currentIntegrationSettings?.permitsingleflow || {})?.length ||
    !isEqual(currentIntegrationSettings?.permitsingleflow, enabledServiceObject?.enabledTriggers)

  const renderList = (title, array, onClickHandler) => {
    if (!areMoreFlowsPossible || !array?.length) return null
    return (
      <Box className='flex flex-col w-full'>
        <Box className='container'>
          {array.map((item) => {
            if (enabledServiceObject?.enabledTriggers?.[item.rowid] && currentIntegrationSettings?.permitsingleflow?.[item.rowid])
              return null

            return (
              <Box className='p-2 grid-item' key={item.rowid}>
                <Card
                  variant='outlined'
                  className='service-block service-block__no-description flex-spaceBetween-center gap-2'
                  onClick={() => onClickHandler(item)}
                >
                  <Box className='flex-start-center gap-2'>
                    <IconWrapper iconUrl={item.iconurl} size='32px' />
                    <Typography className='event-box-text'>{item.name}</Typography>
                  </Box>
                </Card>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }
  return !selectedService ? (
    <Box className='w-100 h-100vh flex-col-center-center overflow-scroll-y p-2 pb-120 pos-rel' sx={{ bgcolor: 'background.default' }}>
      <Box className=' h-100 gap-1  flex-col-start-center preview-section-container '>
        {!isEmbedUrl ? <Breadcrumb /> : null}
        <Box className='w-100 px-2 '>
          <Box className='column gap-2'>
            {showEnabled && lengthOfFlowsPresent && !showFlowsList ? (
              <Button onClick={() => setShowFlowsList(true)} startIcon={<ArrowBackIos />}>
                {heading}
              </Button>
            ) : null}
            {isEmbedUrl ? (
              <Typography variant='h4'>
                {showFlowsList ? '' : 'Add '}
                {heading}
              </Typography>
            ) : showFlowsList ? (
              heading
            ) : null}
            {integrationServiceId !== 'webhook' && !isEmbedUrl && authenticationComponent(integrationService)}
          </Box>
          {showSubHeading && (
            <Typography>
              {pagesubheading ||
                (integrationServiceLoading ? (
                  <Skeleton />
                ) : (
                  `Enhance your ${integrationService?.name || 'webhook'} experience with a diverse range of powerful
                integrations.`
                ))}
            </Typography>
          )}
        </Box>
        {showFlowsList ? (
          <Box className='flex flex-col w-full p-2 gap-2'>
            <Button variant='contained' onClick={() => setShowFlowsList(false)}>
              Add New {buttonHeading}
            </Button>
            {isEmbedUrl ? <CompleteAndInCompleteAutomationsList /> : <EnabledFlows firstServiceId={integrationServiceId} />}
          </Box>
        ) : (
          <Box className='column gap-1 w-100 h-100 '>
            {integrationServiceId !== 'webhook' ? (
              <Box className='flex items-center px-2 w-full gap-2'>
                {integrationService?.iconurl && <img src={integrationService?.iconurl || ''} alt='' width='28px  ' />}

                <Typography variant='h5' className='header-iframe-title'>
                  {integrationService?.name}
                </Typography>
              </Box>
            ) : null}
            {serviceType === 'trigger' && renderList('Triggers', serviceTriggersArray, createflow)}

            {featuredCombinationType ? (
              featuredCombinationType === 'custom' ? (
                <CustomCombinationsComponent createflow={createflow} enabledServiceObject={enabledServiceObject} />
              ) : (
                <DefaultCombinations
                  createflow={createflow}
                  enabledServiceObject={enabledServiceObject}
                  firstServiceId={integrationServiceId}
                />
              )
            ) : null}
            {serviceType !== 'trigger' ? <Add fontSize='large' className='mx-1' /> : null}
            {serviceType && areMoreFlowsPossible && (serviceType !== 'trigger' || integrationServiceId === 'webhook') && (
              <Box className='p-2 w-100 searchbar-parent  column gap-2'>
                <PluginSearchBar
                  autoFocus={mode === MiscTypes.EMBED_MODE}
                  placeholder='Search services by name, description or domain '
                  triggers={['action', 'both']?.includes(serviceType)}
                  getServices={({ servicesArray, isLoading: loading, searchQuery }) => {
                    const obj = {}
                    servicesArray = servicesArray
                      .filter((service) => {
                        if (service.rowid === integrationServiceId) return false
                        const triggerExists = Number(service.triggercount)
                        const actionExists = Number(service.actioncount)
                        if (!triggerExists && !actionExists) return false
                        if (!triggerExists && serviceType === 'action') return false
                        if (!actionExists && serviceType === 'trigger') return false

                        return true
                      })
                      ?.slice(0, searchQuery ? undefined : 20)
                    servicesArray.forEach((service) => {
                      obj[service.rowid] = service
                    })
                    setServicesData({
                      isLoading: loading,
                      pluginsArray: servicesArray,
                      pluginsObj: obj
                    })
                  }}
                />
              </Box>
            )}
            {isLoading && (
              <Box className='container my-4'>
                {Array.from({ length: 16 }, (_, index) => index).map((el) => {
                  return (
                    <Box className='grid-item p-2' key={el}>
                      <Skeleton height='200px' variant='rectangular' width='100%' />
                    </Box>
                  )
                })}
              </Box>
            )}
            {serviceType && areMoreFlowsPossible && (serviceType !== 'trigger' || integrationServiceId === 'webhook') && (
              <Box className='w-100'>
                <Box className='container'>
                  {serviceType !== 'trigger' ? (
                    <Box className='p-2 grid-item'>
                      <Card
                        variant='outlined'
                        onClick={() => createflow({ rowid: 'webhook' }, undefined)}
                        className='service-block gap-3 column '
                      >
                        <Box className='flex-spaceBetween-center  gap-2  '>
                          <Box className='flex-start-center  gap-2 '>
                            <Box className='h-100'>
                              <IconWrapper component={<Webhook />} size='32px' />
                            </Box>
                            <Typography className='service-title '>Webhook</Typography>
                          </Box>
                        </Box>
                        <Typography className='service-description w-100'>
                          Seamlessly trigger <strong>{integrationService?.name}</strong> using a webhook URL. Automate with real-time data
                          exchange.
                        </Typography>
                      </Card>
                    </Box>
                  ) : null}
                  {serviceType !== 'action' && !hideApi ? (
                    <Box className='p-2 grid-item'>
                      <Card
                        variant='outlined'
                        onClick={() => createflow(integrationServiceId === 'webhook' ? { rowid: 'webhook' } : undefined, { rowid: 'api' })}
                        className='service-block gap-3 column '
                      >
                        <Box className='flex-spaceBetween-center  gap-2  '>
                          <Box className='flex-start-center  gap-2 '>
                            <Box className='h-100'>
                              <IconWrapper component={<Api />} size='32px' />
                            </Box>
                            <Typography className='service-title '>HTTP/API Request</Typography>
                          </Box>
                        </Box>
                        <Typography className='service-description w-100'>Import cURL or provide API to run.</Typography>
                      </Card>
                    </Box>
                  ) : null}
                  {serviceType !== 'action' && !hideFunction ? (
                    <Box className='p-2 grid-item'>
                      <Card
                        variant='outlined'
                        onClick={() =>
                          createflow(integrationServiceId === 'webhook' ? { rowid: 'webhook' } : undefined, { rowid: 'function' })
                        }
                        className='service-block gap-3 column '
                      >
                        <Box className='flex-spaceBetween-center  gap-2  '>
                          <Box className='flex-start-center  gap-2 '>
                            <Box className='h-100'>
                              <IconWrapper component={<img src={JSIcon} alt='' />} size='32px' />
                            </Box>
                            <Typography className='service-title '>Custom Logic (JS)</Typography>
                          </Box>
                        </Box>

                        <Typography className='service-description w-100'>Write your own JS code.</Typography>
                      </Card>
                    </Box>
                  ) : null}
                  {renderAvailableServices(pluginsArray.filter((service) => service.domain !== restrictedDomain))}
                </Box>
              </Box>
            )}

            {showTemplates && <Templates handleTemplateClick={createFlowWithTemplate} templatesHeading={templatesHeading} />}
            {showDeletedFlows && <DeletedAndPausedFlows firstServiceId={integrationServiceId} />}
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box id='selected-service-parent-component' className='w-100 h-100 flex   '>
      {integrationServiceId !== 'webhook' && serviceType !== 'action' ? (
        <SelectedServiceForIntegration
          firstService={selectedService?.rowid}
          secondService={integrationServiceId}
          permittedEvents={permittedEvents}
          unpermittedEvents={unpermittedEvents}
          showDrawerOnly
        />
      ) : null}
      <Box className=' h-100 gap-1 items-center flex flex-col overflow-scroll-y w-full  '>
        <Box className=' h-100 gap-1 w-full flex flex-col preview-section-container  '>
          {!isEmbedUrl ? (
            <Breadcrumb />
          ) : (
            <Button onClick={() => navigate(`/integrations/embed/${orgId}/${projectId}`)} startIcon={<ArrowBackIos />}>
              All Apps
            </Button>
          )}
          {serviceId && (
            <Box className='column w-100 gap-2 px-2 '>
              <Box className='flex items-center w-full gap-2'>
                {selectedService?.iconurl && <img src={selectedService?.iconurl || ''} alt='' width='28px  ' />}

                <Typography variant='h4' className='header-iframe-title'>
                  {selectedService ? selectedService?.name || '' : serviceId} Automations
                </Typography>
              </Box>

              {!['api', 'function', 'webhook'].includes(serviceId) && !isEmbedUrl && authenticationComponent(selectedService)}
            </Box>
          )}
          <Box className='w-full flex flex-col'>
            {integrationServiceId === 'webhook' || serviceType === 'action' ? (
              <SelectedPlugToAddStep
                plug={selectedService}
                addActionFunction={(event) => {
                  if (serviceType === 'action') createflow(event)
                  else createflow({ rowid: 'webhook' }, event)
                }}
                showHeader={false}
                addAIAction={false}
                type={serviceType === 'action' ? 'trigger' : 'action'}
              />
            ) : (
              <Box className='flex flex-col w-full  '>
                <SelectedServiceForIntegration
                  firstService={selectedService?.rowid}
                  secondService={integrationServiceId}
                  permittedEvents={permittedEvents}
                  createflow={createflow}
                  unpermittedEvents={unpermittedEvents}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(PreviewSection), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.isEmbedUrl
  ])
)
